<template>
  <v-row>
    <v-col
      cols="6"
      sm="6"
      md="6"
      lg="12"
    >
      <v-card
        class="mt-0 mt-0 d-flex justify-center align-center"
        style="background-color: rgba(24, 59, 148, 1); color: white; width: 100%; height: 128px; gap: 5%; padding: 5%; text-align: right; border-radius: 11px"
        elevation="1"
      >
        <v-icon
          color="white"
          :size="iconSize"
        >
          mdi-chart-areaspline
        </v-icon>
        <div>
          <div :style="textSize">
            {{ currencyFormatter(totalMensal.totalVolumeMes, 0) }} L
          </div>
          <div :style="textSizeDown">
            Total mês
          </div>
        </div>
      </v-card>
    </v-col>
    <v-col
      cols="6"
      sm="6"
      md="6"
      lg="12"
    >
      <v-card
        class="mt-0 d-flex justify-center align-center"
        style="background-color: rgba(24, 59, 148, 1); color: white; width: 100%; height: 128px; gap: 5%; padding: 5%; text-align: right; border-radius: 11px"
        elevation="1"
      >
        <v-icon
          :size="iconSize"
          color="white"
        >
          mdi-chart-pie
        </v-icon>
        <div v-if="window === 0">
          <div :style="textSize">
            {{ currencyFormatter(Math.floor(totalMensal.mediaGeralDiaMes, 0), 0) }} L
          </div>
          <div :style="textSizeDown">
            Média Diária
          </div>
        </div>
        <div v-else>
          <div :style="textSize">
            {{ currencyFormatter(Math.floor(totalMensal.mediaMensalAno, 0), 0) }} L
          </div>
          <div :style="textSizeDown">
            Média Mensal
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
  import { currencyFormatter } from '../../utils/formatter'
  export default {
    props: {
      totalMensal: {
        type: Object,
        default: () => '',
      },
      window: {
        type: Number,
        default: () => 0,
      },
    },
    data () {
      return {
        currencyFormatter,
      }
    },
    computed: {
      textSize () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 'font-weight: 700; font-size: 4vw;'
          case 'sm': return 'font-weight: 700; font-size: 3vw'
          case 'md': return 'font-weight: 700; font-size: 2vw'
          case 'lg': return 'font-weight: 700; font-size: 2vw'
          case 'xl': return 'font-weight: 700; font-size: 2vw'
          case 'xxl': return 'font-weight: 700; font-size: 2vw'
          default: return 'color: white;'
        }
      },
      textSizeDown () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 'font-size: 3vw'
          case 'sm': return 'font-size: 2vw'
          case 'md': return 'font-size: 16px'
          case 'lg': return 'font-size: 18px'
          case 'xl': return 'font-size: 18px'
          case 'xxl': return 'font-size: 18px'
          default: return 'font-size: 16px'
        }
      },
      iconSize () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '10vw'
          case 'sm': return '9vw'
          case 'md': return '5vh'
          case 'lg': return '7vh'
          case 'xl': return '7vh'
          case 'xxl': return '7vh'
          default: return '50'
        }
      },
    },
  }
</script>
