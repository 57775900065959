<template>
  <div v-if="( (selecaoFazenda === null || selecaoFazenda === '' || selecaoFazenda === undefined) || ((selecaoProdutor === null || selecaoProdutor === '' || selecaoProdutor === undefined) && this.$store.state.user.roles[0].slug === 'tecnico') )">
    <v-alert
      color="warning"
      icon="mdi-account-cowboy-hat"
      dark
      dismissible
    >
      <span> <h2>{{ this.$store.state.user.roles[0].slug === 'tecnico' ?
        'Por favor selecione um Produtor e uma Fazenda no canto superior direito da loja!' :
        'Por favor selecione uma Fazenda no canto superior direito da loja!' }} </h2></span>
    </v-alert>
  </div>
  <base-loading v-else-if="!ready" />
  <v-container
    v-else
    fluid
    style="padding: 30px;"
  >
    <div
      class="font-weight-bold"
      style="font-size: 34px; color: #183B94; margin-bottom: 30px"
    >
      Volume
    </div>
    <v-window
      v-model="window"
    >
      <v-window-item
        v-for="periodo in anualOuMensal"
        :key="periodo"
      >
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="12"
            sm="12"
          >
            <v-date-picker
              v-model="mes"
              class="mt-0 mr-0"
              type="month"
              color="#183B94"
              width="100%"
              :show-current="false"
              elevation="1"
              @change="handleDataFilter"
            />
          </v-col>
          <v-col
            cols="12"
            lg="8"
            md="12"
            sm="12"
          >
            <v-row>
              <v-col cols="12">
                <v-btn-toggle
                  v-model="window"
                  active-class="selected-btn"
                  style="opacity: 1; border-radius: 8px;"
                >
                  <v-btn
                    :min-width=" $vuetify.breakpoint.xs ? 120 : 270"
                    height="60"
                    elevation="1"
                    style="font-size: 20px; font-weight: 700;"
                    @click="handleDataFilter()"
                  >
                    Mensal
                  </v-btn>

                  <v-btn
                    style="font-size: 20px; font-weight: 700;"
                    :min-width=" $vuetify.breakpoint.xs ? 120 : 270"
                    height="60"
                    elevation="1"
                    @click="yearSearch()"
                  >
                    Anual
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col
                cols="12"
                xl="4"
                lg="5"
                md="12"
                sm="12"
              >
                <volume-infos
                  :total-mensal="volume_total"
                  :window="window"
                />
              </v-col>
              <v-col
                cols="12"
                xl="8"
                lg="7"
                md="12"
                sm="12"
              >
                <v-card
                  v-if="window === 0"
                  class="mt-0"
                  style="height: 298px;"
                  elevation="1"
                >
                  <v-carousel
                    v-model="tab"
                    :show-arrows="false"
                    hide-delimiters
                    :cycle="false"
                    style="background-color: white; border-radius: 8px;"
                    height="100%"
                  >
                    <v-carousel-item
                      v-for="(dia, indextab) in volumeMensalCarousel"
                      :key="indextab"
                      style="padding: 20px 20px 20px 20px;"
                    >
                      <div
                        class="font-weight-bold"
                        style="font-size: 18px; color: #183B94; display: flex; justify-content: space-between"
                      >
                        <v-btn
                          color="white"
                          elevation="0"
                          @click="previousSlide"
                        >
                          <v-icon style="color: #183B94;">
                            mdi-less-than
                          </v-icon>
                        </v-btn>
                        {{ moment(mes + '-' + dia.dia).format('dddd, DD [de] MMMM [de] YYYY') }}
                        <v-btn
                          color="white"
                          elevation="0"
                          @click="nextSlide"
                        >
                          <v-icon style="color: #183B94">
                            mdi-greater-than
                          </v-icon>
                        </v-btn>
                      </div>
                      <div
                        style="padding: 20px 30px 0px 30px; display: flex; justify-content: space-between; align-items: center; margin-top: 20px;"
                      >
                        <p style="color: #183B94; font-size: 18px; font-weight: 500;">
                          Total
                        </p>
                        <p style="font-weight: 500;">
                          {{ currencyFormatter(dia.volume, 0) }} litros
                        </p>
                      </div>
                      <v-divider
                        class="custom-divider"
                        height="10px"
                        max-height="5px"
                        style="height: 3px; max-height: 50px;"
                      />
                      <div
                        style="padding: 20px 30px 0px 30px; display: flex; justify-content: space-between;"
                      >
                        <p style="color: #183B94; font-size: 18px; font-weight: 500">
                          Não conforme
                        </p>
                        <p style="font-weight: 500;">
                          {{ currencyFormatter(dia.volumeNaoConforme, 0) }} litros
                        </p>
                      </div>
                      <v-divider
                        class="custom-divider"
                        height="10px"
                        max-height="5px"
                        style="height: 3px; max-height: 50px;"
                      />
                    </v-carousel-item>
                  </v-carousel>
                </v-card>
                <v-card
                  v-else
                  class="mt-0"
                  outlined
                  elevation="1"
                >
                  <v-simple-table
                    fixed-header
                    height="295px"
                  >
                    <template v-slot:default>
                      <thead class="text-center">
                        <tr>
                          <th
                            class="text-center"
                            style="color: #183B94; font-weight: 700; background-color: white;"
                          >
                            Mês
                          </th>
                          <th
                            class="text-center"
                            style="color: #183B94; font-weight: 700; background-color: white;"
                          >
                            Total do mês
                          </th>
                          <th
                            class="text-center"
                            style="color: #183B94; font-weight: 700; background-color: white;"
                          >
                            Média diária
                          </th>
                        </tr>
                      </thead>
                      <tbody class="text-center">
                        <tr
                          v-for="(dia, index) in volumeAnualCarousel"
                          :key="index"
                        >
                          <td>
                            {{ dia.mes }}
                          </td>
                          <td>
                            {{ currencyFormatter(dia.volume, 0) }} L
                          </td>
                          <td>
                            {{ dia.mediaDiária }} L
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="window === 0 "
            cols="12"
          >
            <material-chart-card
              v-if="volume_grafico_mes.data"
              :options="optInter"
              :data="volume_grafico_mes.data"
              class="mt-5"
              type="Bar"
              color="#143693"
              title="Volume por dia"
              icon="mdi-chart-timeline-variant-shimmer"
              elevation="3"
              min-height="430px"
            />
          </v-col>
          <v-col
            v-else
            cols="12"
          >
            <material-chart-card
              v-if="volume_grafico_ano.data"
              :options="optEvolucao"
              :data="volume_grafico_ano.data"
              class="mt-5"
              type="Line"
              color="#143693"
              title="Volume por mês"
              icon="mdi-chart-timeline-variant-shimmer"
              elevation="3"
              min-height="430px"
            />
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script>
  import { mapGetters, mapState, mapActions } from 'vuex'
  import MaterialChartCard from '../../components/base/MaterialChartCard'
  import VolumesStore, { BOOTSTRAP_FAZENDA } from '@/store/modules/volumes'
  import Tooltips from 'chartist-plugin-tooltips-updated'
  import Legend from 'chartist-plugin-legend'
  import { currencyFormatter } from '../../utils/formatter'
  import VolumeInfos from './VolumeInfos.vue'

  export default {
    name: 'VolumeProdutor',
    components: {
      MaterialChartCard,
      VolumeInfos,
    },
    data () {
      return {
        window: 0,
        anualOuMensal: ['Mensal', 'Anual'],
        Fazenda: '',
        media: { value: 0 },
        tab: 0,
        ano: '',
        currencyFormatter,
        optInter: {
          low: 0,
          showArea: false,
          stackBars: true,
          seriesBarDistance: 17,
          axisX: {
            offset: 10,
            showGrid: false,
          },
          axisY: {
            onlyInteger: true,
            offset: 60,
            labelInterpolationFnc: function (value) {
              return value + ' L'
            },
          },
          lineSmooth: this.$chartist.Interpolation.monotoneCubic({
            tension: 0.1,
          }),
          height: 290,
          plugins: [
            Legend(),
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return meta + '<br />' + currencyFormatter(value, 0) + ' L' },
            }),
          ],
          chartPadding: {
            top: 30,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        optEvolucao: {
          low: 0,
          showArea: true,
          showPoint: true,
          axisX: {
            offset: 20,
            showGrid: true,
            showLabel: true,
          },
          axisY: {
            onlyInteger: true,
            offset: 70,
            labelInterpolationFnc: function (value) {
              return value + ' L'
            },
          },
          lineSmooth: this.$chartist.Interpolation.monotoneCubic({
            tension: 0.1,
          }),
          height: 290,
          width: 1300,
          plugins: [
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return meta + '<br />' + value + ' Litros' },
            }),
            this.pontos({
              textAnchor: 'middle',
              labelInterpolationFnc: function (value) { return value },
            }),
          ],
          chartPadding: {
            top: 50,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      }
    },
    computed: {
      ...mapState('fazendas', ['selecaoFazenda']),
      ...mapState('produtores', ['selecaoProdutor']),
      ...mapState('volumes', ['ready', 'volume_grafico_mes', 'volume_grafico_ano', 'volume_total']),
      ...mapState({ user: state => state.user }),
      ...mapGetters(['hasPermission']),

      volumeMensalCarousel () {
        const meses = [
          'Janeiro', 'Feveveiro', 'Março', 'Abril', 'Maio', 'Junho',
          'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
        const dados = this.volume_grafico_mes.data.labels.map((label, index) => {
          const mes = this.volume_grafico_mes.data.series[0].data[index].meta
          const [day, mouth] = mes.split('/')
          const mesFormatado = day + ' de ' + meses[parseInt(mouth) - 1]
          return {
            dia: label,
            mes: mesFormatado,
            volume: this.volume_grafico_mes.data.series[0].data[index].value,
            volumeNaoConforme: this.volume_grafico_mes.data.series[1].data[index].value,
          }
        })
        return dados
      },
      volumeAnualCarousel () {
        const mesesAbreviados = [
          'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
          'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
        const dados = this.volume_grafico_ano.data.labels.map((label, index) => {
          const [month, year] = label.split('/')
          const mesFormatado = mesesAbreviados[parseInt(month) - 1] + '/' + year
          const volume = this.volume_grafico_ano.data.series[0].data[index].value
          const diasNoMes = new Date(parseInt(year), parseInt(month), 0).getDate()
          const mediaDiaria = volume / diasNoMes
          return {
            mes: mesFormatado,
            volume: volume,
            mediaDiária: currencyFormatter(Math.floor(mediaDiaria, 0), 0),
          }
        })
        return dados.reverse()
      },
    },
    created () {
      if (!this.$store.hasModule('volumes')) { this.$store.registerModule('volumes', VolumesStore) }
      if (this.selecaoFazenda !== null) {
        const date = new Date()
        const data_i = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().slice(0, 10)
        const data_f = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().slice(0, 10)
        this.mes = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().slice(0, 7)
        this.Fazenda = this.selecaoFazenda
        this.BOOTSTRAP_FAZENDA({ data_inicial: data_i, data_final: data_f, id: this.selecaoFazenda.id })
      }
    },
    updated () {
      if (this.Fazenda !== this.selecaoFazenda && this.selecaoFazenda) {
        const date = new Date()
        const data_i = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().slice(0, 10)
        const data_f = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().slice(0, 10)
        this.mes = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().slice(0, 7)
        this.Fazenda = this.selecaoFazenda
        this.BOOTSTRAP_FAZENDA({ data_inicial: data_i, data_final: data_f, id: this.selecaoFazenda.id })
      }
    },
    methods: {
      ...mapActions('volumes', [BOOTSTRAP_FAZENDA]),
      handleDataFilter (value) {
        if (value) {
          const data_i = new Date(value.slice(0, 4), value.slice(5, 7) - 1, 1).toISOString().slice(0, 10)
          const data_f = new Date(value.slice(0, 4), value.slice(5, 7), 0).toISOString().slice(0, 10)
          const dataAnoPassado = new Date(value.slice(0, 4) - 1, value.slice(5, 7) - 1, 1).toISOString().slice(0, 10)
          if (this.window === 0) {
            this.BOOTSTRAP_FAZENDA({ data_inicial: data_i, data_final: data_f, id: this.selecaoFazenda.id })
          } else {
            this.BOOTSTRAP_FAZENDA({ data_inicial: dataAnoPassado, data_final: data_f, id: this.selecaoFazenda.id })
          }
        } else {
          const date = new Date()
          const data_i = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().slice(0, 10)
          const data_f = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().slice(0, 10)
          this.mes = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().slice(0, 7)
          this.BOOTSTRAP_FAZENDA({ data_inicial: data_i, data_final: data_f, id: this.selecaoFazenda.id })
        }
      },
      yearSearch () {
        const date = new Date()
        const dataAnoPassado = new Date(date.getFullYear() - 1, date.getMonth() + 1, 1).toISOString().slice(0, 10)
        const data_f = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().slice(0, 10)

        this.mes = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().slice(0, 7)
        this.BOOTSTRAP_FAZENDA({ data_inicial: dataAnoPassado, data_final: data_f, id: this.selecaoFazenda.id })
      },
      nextSlide () {
        if (this.tab < this.volume_grafico_mes.data.labels.length - 1) {
          this.tab += 1
        } else {
          this.tab = 0 // Volta ao início se estiver na última aba
        }
      },
      previousSlide () {
        if (this.tab > 0) {
          this.tab -= 1
        } else {
          this.tab = this.volume_grafico_mes.data.labels.length - 1 // Vai para a última aba se estiver na primeira
        }
      },
      pontos (options) {
        var defaultOptions = {
          labelClass: 'ct-label',
          labelOffset: {
            x: 0,
            y: -10,
          },
          textAnchor: 'middle',
          align: 'center',
          labelInterpolationFnc: this.$chartist.noop,
        }

        var labelPositionCalculation = {
          point: function (data) {
            return {
              x: data.x,
              y: data.y,
            }
          },
          bar: {
            left: function (data) {
              return {
                x: data.x1,
                y: data.y1,
              }
            },
            top: function (data) {
              return {
                x: data.x1,
                y: data.y2,
              }
            },
            center: function (data) {
              return {
                x: data.x1 + (data.x2 - data.x1) / 2,
                y: data.y1,
              }
            },
            right: function (data) {
              return {
                x: data.x2,
                y: data.y1,
              }
            },
          },
        }

        options = this.$chartist.extend({}, defaultOptions, options)

        function addLabel (position, data) {
          var value = data.value.x !== undefined && data.value.y
            ? (data.value.x + ', ' + data.value.y)
            : data.value.y || data.value.x

          value = value === undefined ? 0 : value

          data.group.elem('text', {
            x: position.x + options.labelOffset.x,
            y: position.y + options.labelOffset.y,
            style: 'text-anchor: ' + options.textAnchor,
          }, options.labelClass).text(options.labelInterpolationFnc(value))
        }

        return function ctPointLabels (chart) {
          chart.on('draw', function (data) {
            var positonCalculator = (labelPositionCalculation[data.type] && labelPositionCalculation[data.type][options.align]) || labelPositionCalculation[data.type]
            if (positonCalculator) {
              addLabel(positonCalculator(data), data)
            }
          })
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep .v-card--material-chart .v-card--material__heading .ct-bar {
    stroke-width: 2% !important;
  }

  .selected-btn {
    background: #183B94 !important;
    color: rgb(255, 255, 255) !important;
  }

  .theme--light.v-btn--active:hover::before, .theme--light.v-btn--active::before {
    opacity: 0 !important;
}
</style>
